<template>
	<div class="grid">
		<div class="col-12">
			<Toast/>
			<DataTable ref="dt" dataKey="id"
						filterDisplay="menu"
						v-model:filters="filters"
						v-model:selection="selectedReceipts"
						:showGridlines=true
						:globalFilterFields="['senderName','recipientName','province','note']"
						:value="receipts" :paginator="true" :rows="defaultPageRows"
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="rowPerPageOptions"
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords} receipts" responsiveLayout="scroll">
				<template #header>
					<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
						<!-- <h5 class="m-0">{{$t('receipt.list_title')}}</h5> -->
						<div class="my-2">
							<Button type="button" icon="pi pi-filter-slash" :label="$t('button.clear')" class="p-button-outlined mr-2" @click="clearFilters()"/>
							<!-- <Button type="button" icon="pi pi-box" :label="$t('button.pack')" class="p-button mr-2" @click="onPacking()"/> -->
						</div>
						<span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Search..." />
            </span>
					</div>
				</template>
				<!-- <Column selectionMode="single" headerStyle="width: 3rem"></Column> -->
				
				<Column headerStyle="width:5rem;">
					<template #body="slotProps">
						<Button icon="pi pi-box" class="p-button mr-2" @click="openReceiptPacking(slotProps.data)" v-if="is_packable(slotProps.data)"/>
					</template>
				</Column>
				
				<Column field="createdDate" :header="$t('receipt.receiptDate')" :sortable="true" dataType="date" headerStyle="width:14%; min-width:10rem;">
					<template #body="slotProps">
						<span class="p-column-title">{{$t('receipt.receiptDate')}}</span>
						{{this.formatDate(slotProps.data.createdDate)}}
					</template>
					<template #filter="{filterModel}">
						<Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
					</template>
				</Column>
				<Column field="receiptCode" :header="$t('receipt.receiptCode')" :sortable="true" headerStyle="width:14%; min-width:10rem;">
					<template #body="slotProps">
						<span class="p-column-title">{{$t('receipt.receiptCode')}}</span>
						{{slotProps.data.receiptCode}}
					</template>
					<template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" :placeholder="$t('common.search_by_field',[$t('receipt.receiptCode')])"/>
          </template>
				</Column>
				<Column field="packageNumber" :header="$t('receipt.pieces')"  dataType="numeric" headerStyle="width:8%; min-width:4rem;">
					<template #body="slotProps">
						<span class="p-column-title">{{$t('receipt.pieces')}}</span>
						{{slotProps.data.packageNumber}}
					</template>
					<template #filter="{filterModel}">
              <InputNumber v-model="filterModel.value" class="p-column-filter" :placeholder="$t('common.search_by_field',[$t('receipt.pieces')])"/>
          </template>
				</Column>
				<Column field="weight" :header="$t('receipt.weight')" dataType="numeric" headerStyle="width:8%; min-width:4rem;">
					<template #body="slotProps">
						<span class="p-column-title">{{$t('receipt.weight')}}</span>
						{{slotProps.data.weight}}
					</template>
					<template #filter="{filterModel}">
              <InputNumber v-model="filterModel.value" class="p-column-filter" :placeholder="$t('common.search_by_field',[$t('receipt.weight')])"/>
          </template>
				</Column>
				<Column field="senderName" :header="$t('receipt.sender')" dataType="text" :sortable="true" headerStyle="width:14%; min-width:10rem;">
					<template #body="slotProps">
						<span class="p-column-title">{{$t('receipt.sender')}}</span>
						{{slotProps.data.senderName}}
					</template>
					<template #filter="{filterModel}">
							<InputText type="text" v-model="filterModel.value" class="p-column-filter" :placeholder="$t('common.search_by_field',[$t('receipt.senderName')])"/>
					</template>
				</Column>
				<Column field="recipientName" :header="$t('receipt.recipient')" :sortable="true" headerStyle="width:14%; min-width:10rem;">
					<template #body="slotProps">
						<span class="p-column-title">{{$t('receipt.recipient')}}</span>
						{{slotProps.data.recipientName}}
					</template>
					<template #filter="{filterModel}">
							<InputText type="text" v-model="filterModel.value" class="p-column-filter" :placeholder="$t('common.search_by_field',[$t('receipt.recipientName')])"/>
					</template>
				</Column>

				<Column field="processStatus" filterField="processStatus" :header="$t('receipt.packingStatus')" :sortable="true" :showFilterMatchModes="false" headerStyle="width:14%; min-width:10rem;">
					<template #body="slotProps">
						<span class="p-column-title">{{$t('receipt.packingStatus')}}</span>
						{{$t('receipt.processStatus_' + slotProps.data.processStatus)}}
					</template>
					<template #filter="{filterModel}">
						<div class="mb-3 font-bold">Status Picker</div>
						<MultiSelect v-model="filterModel.value" :options="processStatus" placeholder="Any" class="p-column-filter">
								<template #option="slotProps">
									{{$t('receipt.processStatus_' + slotProps.option)}}
								</template>
						</MultiSelect>
						<!--
						<Dropdown v-model="filterModel.value" :options="processStatus" placeholder="Any" class="p-column-filter" :showClear="true">
                <template #value="slotProps">

                    <span :class="'customer-badge status-' + slotProps.value" v-if="slotProps.value">{{slotProps.value}}</span>
                    <span v-else>{{slotProps.placeholder}}</span>

                </template>
								<template #option="slotProps">
                    <span :class="'customer-badge status-' + slotProps.option">{{slotProps.option}}</span>
                </template>
            </Dropdown>
						-->
					</template>
				</Column>
				<Column field="note" :header="$t('receipt.note')" :sortable="true" headerStyle="min-width:10rem;">
					<template #body="slotProps">
						<span class="p-column-title">{{$t('receipt.note')}}</span>
						{{slotProps.data.note}}
					</template>
					<template #filter="{filterModel}">
							<InputText type="text" v-model="filterModel.value" class="p-column-filter" :placeholder="$t('common.search_by_field',[$t('receipt.note')])"/>
					</template>
				</Column>

			</DataTable>
		</div>
	</div>

</template>

<script>
import formatter from '@/utils/formatter';
import ReceiptService from '@/service/ReceiptService'
import filter from '@/mixins/filter'
import Consts from '@/utils/consts'
import adjustment from '@/utils/adjustment'
export default {
	components: {},
	mixins: [filter],
	data() {
		return {
			receipts: null,
			filters: {},
			selectedReceipts: [],
			rowPerPageOptions: Consts.ROW_PER_PAGE_OPTIONS,
			defaultPageRows: Consts.DEFAULT_PAGE_ROWS,
			processStatus:['inited','packed','shipped']
		}
	},
	created() {
		this.initGlobalFilter(this.filters);
	},
	mounted() {
		this.initColumnFilters(this.$refs.dt, this.filters);
		//this.filters['processStatus'] = {operator: FilterOperator.AND, constraints: [{value: 'inited', matchMode:FilterMatchMode.CONTAINS}]};
		ReceiptService.getUnpackedReceipt().then(data => {
			adjustment.adjustDateValues(data,['createdDate']);
			this.receipts = data;
		});
	},
	methods: {
		formatDate(jsDate) {
			return formatter.formatDate(jsDate);
		},
		openReceiptPacking(receipt) {
			this.$router.push({ name: 'agent_package_edit', params: {receiptId:receipt.id} })
		},
		is_packable(item) {
			if (!item || !item.processStatus) return false;
			return item.processStatus == 'inited' || item.processStatus == 'packed';
		},
		/*
		 * Viettai: change selected receipts processStatus to packed
		 * 2022 Sep 13
		*/
		onPacking() {
			if (this.selectedReceipts.length > 0) {
				let listIds = this.selectedReceipts.map(item => item.id);
				ReceiptService.changeReceiptStatus2Packed(listIds).then(res => console.log(res));
				/*
				let big_receipts = this.selectedReceipts.filter(receipt => receipt.packageNumber > 1);
				if (big_receipts.length > 0) {
					this.$toast.add({
						severity:'error',
						summary: this.$t('error.receipt_invalid'),
						detail: this.$t('error.select_receipt_with_single_package'),
						life: 3000
					});
				} else {
					let listIds = this.selectedReceipts.map(item => item.id);
					ReceiptService.packReceipts(listIds).then(res => console.log(res));
				}
				*/
			} else {
				this.$toast.add({
					severity:'error',
					summary: this.$t('error.receipt_missing'),
					detail: this.$t('error.select_receipt_with_single_package'),
					life: 3000
				});
			}
		}
	}
}
</script>

<style scoped lang="scss">
@import '@/assets/demo/badges.scss';
</style>
